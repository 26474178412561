import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, filter, switchMap  } from 'rxjs/operators';
import { RUTAS } from 'src/app/services/consts/rutas';
import { Response } from 'src/app/interfaces/general/response'
import { Observable } from 'rxjs';
import { SUPER_EXPR } from '@angular/compiler/src/output/output_ast';
import { ServicioPadre } from '../_general_/servicioPadre.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})

export class EmpresaService extends ServicioPadre {
  private HEADERS = new HttpHeaders().set('Content-Type', 'application/json');
  private TABLE = 'empresa';

  constructor(private httpClient: HttpClient , public SnackBar:MatSnackBar ) {
    super(httpClient,SnackBar);
  }

  public async ListaEnvioKilometros() : Promise<Response<any>> {
    return await this.PeticionGET(this.TABLE+'/ListaKmEmpresa');
  }

  public async getId(PersonaForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/IdFruver' , PersonaForm);
  }

  
  public async ListaFruvers(PersonaForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/ListaFruvers' , PersonaForm);
  }

  public async ObtenerDatos(PersonaForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/datos' , PersonaForm);
  }

  public async ActualizarFotoEmpresa(EmpresaForm) : Promise<Response<any>> {
    return await this.PeticionPUTDatos(this.TABLE+'/foto' , EmpresaForm);
  }

  public async IngresarInformacion(EmpresaForm) : Promise<Response<any>> {
    return await this.PeticionPOST(this.TABLE+'/enviar' , EmpresaForm);
  }
 
 

  

 



 

}

