import { Injectable } from '@angular/core';
import { callbackify } from 'util';
import { ReadVarExpr } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor() { }

  public UploadFile(files, callback) {
    for (let file of files) { /*  Recorre todo los elementos de tipo file.files  */
      let reader = new FileReader();
      reader.readAsDataURL(file); /*  Lee el formato como base64 */
      reader.onload = (_event) => {
        callback(reader, file); /*  llama el callback con el reader y filer  */
      }
    }
  }


  public URLaBASE64(url: string, callback) {
    var xhr = new XMLHttpRequest();
    let array = [];
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        array = callback(reader);
      }

      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
    return array;
  }

  public Redimencion(base64, callback) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext('2d');
    var cw = canvas.width;
    var ch = canvas.height;
    var maxW = 800;
    var maxH = 800;
    var img = new Image;
    img.src = base64;
    img.onload = function () {
      var iw = img.width;
      var ih = img.height;
      canvas.width = 200;
      canvas.height = 200;
      ctx.drawImage(img, 0, 0, 200, 200);
      canvas.remove();
      callback(canvas.toDataURL("image/jpeg"));
    }
  }



}
